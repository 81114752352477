import React, { Fragment, FC } from "react";
import Navigation from "./navigation";

const Layout: FC = props => (
  <Fragment>
    <main>
      <div className="grid grid-centered">
        <div className="grid-cell">
          <Navigation />
          {props.children}
          <PageFooter />
        </div>
      </div>
    </main>
  </Fragment>
);

const PageFooter = () => (
  <footer className="footer">
    <p>
      Content © Copyright 2020. Patrick Lehner. All Rights Reserved.
      <br />
      Design based on{" "}
      <a
        href="https://github.com/nielsenramon/chalk"
        rel="noreferrer noopener"
        target="_blank"
        title="Chalk on GitHub"
      >
        Chalk
      </a>{" "}
      by{" "}
      <a
        href="https://nielsenramon.com"
        rel="noreferrer noopener"
        target="_blank"
        title="Nielson Ramon's website"
      >
        Nielsen Ramon
      </a>
      .
    </p>
  </footer>
);

export default Layout;

import React, { FC } from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

interface NavigationProps {
  siteMetadata: {
    title: string;
    social: {
      twitter: string;
      email: string;
    };
  };
}

const Navigation: FC<NavigationProps> = ({ siteMetadata }) => (
  <nav className="header-nav">
    <Link to="/" className="header-logo" title={siteMetadata.title}>
      {siteMetadata.title}
    </Link>

    <ul className="header-links">
      <li>
        <a href={`mailto:${siteMetadata.social.email}`} title="Email">
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </li>
    </ul>
  </nav>
);

const NavigationWithQuery: FC = props => (
  <StaticQuery
    query={navigationQuery}
    render={data => (
      <Navigation siteMetadata={data.site.siteMetadata} {...props} />
    )}
  />
);

const navigationQuery = graphql`
  query NavigationQuery {
    site {
      siteMetadata {
        title
        social {
          email
        }
      }
    }
  }
`;

export default NavigationWithQuery;

import React, { FC } from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface Props {
  description?: string;
  title?: string;
  lang?: string;
  meta?: HTMLMetaElement[];
  keywords?: string[];
}

export const MetaData: FC<Props> = ({
  description,
  lang,
  meta,
  keywords,
  title,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      defaultTitle={site.siteMetadata.title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
    >
      <html lang={lang} className="light_theme" />
      {title && <title>{title}</title>}
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content={`website`} />
      <meta name="twitter:card" content={`summary`} />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      {meta}
      {keywords && keywords.length > 0 && (
        <meta name="keywords" content={keywords.join(`, `)} />
      )}
    </Helmet>
  );
};

MetaData.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
};
